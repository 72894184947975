import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import CampaignComment from '@/components/CampaignComment'
import CampaignCommentForm from '@/components/CampaignComment/CampaignCommentForm'
import { Button, Type } from '@/components/common'
import { ArrowRight, ChevronDown } from '@/components/svg'
import { useExperiment } from '@/experimentation'
import { Comment } from '@/services/api/publicComments'
import useAuth from '@/services/hooks/useAuth'
import useComments from '@/services/hooks/useComments'

export interface Props {
  campaignName: string
  campaignSlug: string
  isOpen: boolean
  className?: string
}

const PublicComments: React.FC<Props> = ({
  campaignName,
  campaignSlug,
  isOpen,
  className,
}) => {
  const { user, openModal, startAuth } = useAuth()
  const [page, setPage] = useState(1)

  const { comments, isLoading, postComment } = useComments({
    campaign: campaignSlug,
    page,
    isPaginated: false,
  })
  const [isOAuth2] = useExperiment('ellis_island_oauth2', true)

  const handleSubmit = useCallback(
    (userId: string, text: string) => {
      if (!user?.isValidEmail && isOAuth2) {
        openModal('eiVerifyAccount')
      } else {
        return postComment({ userId, text, campaign: campaignSlug })
      }
    },
    [user?.isValidEmail, isOAuth2, openModal, postComment, campaignSlug]
  )

  if (!isOpen && comments.results.length === 0) {
    return null
  }

  return (
    <div id="section-comments" className={className}>
      <ArrowRight className="absolute left-0 top-8 text-core-oxide-core" />
      <Type as="h2" variant="title-lg" className="mb-6 font-bold">
        Issuer Q&A
      </Type>
      {isOpen && (
        <Type as="p" variant="paragraph-md" className="mb-6">
          Ask questions to the ‘{campaignName}’ team in regards to this
          offering. Remember to be respectful since this is a public space.
        </Type>
      )}

      {isOpen && (
        <div className="mb-6">
          {user ? (
            <CampaignCommentForm
              campaignSlug={campaignSlug}
              onSubmit={handleSubmit}
              placeholder="Enter your question here"
            />
          ) : (
            <div className="border-t border-core-gray-200 pt-6">
              <Type as="p" variant="title-md" className="mb-6">
                You must sign in order to submit your questions.
              </Type>
              <Button
                color="black"
                className="w-full sm:w-auto"
                onClick={() => (isOAuth2 ? startAuth() : openModal())}
              >
                Log In
              </Button>
            </div>
          )}
        </div>
      )}

      {comments.results.length > 0 && (
        <ul
          className={classNames({
            'transition-opacity': true,
            'opacity-50': isLoading,
          })}
        >
          {comments.results.map((comment: Comment) => (
            <li key={comment.id} className="group">
              <CampaignComment
                campaignName={campaignName}
                {...comment}
                className="border-b border-core-gray-200 py-6 group-first:border-t"
              />
            </li>
          ))}
        </ul>
      )}

      {comments.next && (
        <Button
          className="mt-6 self-start"
          size="sm"
          variant="link"
          onClick={() => setPage((cur) => cur + 1)}
          disabled={isLoading}
        >
          Show more
          <ChevronDown className="inline h-[18px] w-[18px]" />
        </Button>
      )}
    </div>
  )
}

export default PublicComments
