export function scaleDimensions(
  size: number,
  defaultWidth: number,
  defaultHeight: number
): { width: number; height: number } {
  if (defaultWidth < defaultHeight) {
    return {
      width: size,
      height: (size / defaultWidth) * defaultHeight,
    }
  }
  return {
    width: (size / defaultHeight) * defaultWidth,
    height: size,
  }
}

export interface BaseLogoProps {
  className?: string
  height?: number
}

export interface BaseIconProps {
  className?: string
  size?: number
  vertical?: boolean
  fill?: boolean
  onClick?: React.MouseEventHandler<SVGSVGElement>
}

export interface OneColorIconProps extends BaseIconProps {
  color?: string
}

export interface OneColorLogoProps extends BaseLogoProps {
  color?: string
}

export const paths = {
  appleAppStore: 'https://apps.apple.com/us/app/angel-studios/id1473663873',
  appleTv:
    'https://support.angel.com/hc/en-us/articles/4622534841751-How-to-Download-the-Angel-TV-App-Apple-TV',
  fireTv:
    'https://support.angel.com/hc/en-us/articles/4637488689431-How-to-Download-the-Angel-TV-App-Fire-TV',
  googlePlayStore: 'https://link.angel.com/zGEh9m8o5Lb',
  googleTv:
    'https://support.angel.com/hc/en-us/articles/4633478130839-How-to-Download-the-Angel-TV-App-Google-TV',
  lgTv: 'https://support.angel.com/hc/en-us/articles/23534496032791-How-to-Download-the-Angel-TV-App-LG-TV',
  microsoft:
    'https://support.angel.com/hc/articles/24474422103191-How-to-Download-the-Angel-TV-App-Xbox',
  roku: 'https://support.angel.com/hc/en-us/articles/4467868786839-How-to-Download-the-Angel-TV-App-Roku',
  samsungTv:
    'https://support.angel.com/hc/en-us/articles/23534034041111-How-to-Download-the-Angel-TV-App-Samsung-TV',
}

export const baseLogoHeight = 30
