import React, { useMemo } from 'react'
import { Text } from '@/components/common'
import { getLivestreamComments, Comment } from '@/services/api/endpoints'
import useApi from '@/services/hooks/useApiV2'

interface Props {
  video: string
}

const CommentSection = ({ video }: Props) => {
  const { data: comments = [], isError } = useApi<Comment[]>({
    key: `livestream-comments/${video}`,
    request: () => getLivestreamComments({ video }),
  })

  // TODO: need to check if backend is setting duplicate comments
  const deduped = useMemo(() => {
    if (isError || !Array.isArray(comments)) return []

    return comments
      .map(({ author_name, date, ...rest }: Comment) => ({
        ...rest,
        id: `${author_name} ${date}`,
        author_name,
        date,
      }))
      .reduce((acc: Comment[], cur: Comment) => {
        if (!acc.find(({ id }) => cur.id === id)) {
          return [...acc, cur]
        }

        return acc
      }, [])
  }, [comments, isError])

  if (!comments.length || isError) return null

  return (
    <div className="background-gray h-52 w-full overflow-scroll p-4 shadow">
      {deduped.map((comment: Comment) => (
        <div
          key={comment.id}
          className="py-1"
          style={{ wordBreak: 'break-word' }}
        >
          <Text as="div" preset="body.sm" className="text-gray">
            {comment.author_name}
          </Text>
          <Text as="div" preset="body.md">
            {comment.text}
          </Text>
        </div>
      ))}
    </div>
  )
}

export default CommentSection
