import { useCallback, useState } from 'react'
import { Document } from '@contentful/rich-text-types'
import classNames from 'classnames'
import { IMediaGalleryItemFields } from 'src/types/generated/contentful'
import {
  ButtonBase,
  CarouselNav,
  Modal,
  TextBlock,
  Type,
} from '@/components/common'
import Image from '@/components/contentful/Image'
import { Play } from '@/components/svg'
import { Campaign } from '@/services/api/campaign'
import useAuth from '@/services/hooks/useAuth'
import useCarousel from '@/services/hooks/useCarousel'
import MediaGalleryCarousel from './MediaGalleryCarousel'

export interface Props {
  title?: string
  description?: Document
  items: (IMediaGalleryItemFields & { id: string })[]
  campaign: Campaign
  className?: string
}

const PlayIcon = ({ size }: { size: 'sm' | 'lg' }) => (
  <span
    className={classNames(
      'absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]',
      'flex items-center justify-center',
      'rounded-full bg-white opacity-75 ',
      { 'h-8 w-8': size === 'sm', 'h-12 w-12': size === 'lg' }
    )}
  >
    <Play
      className={classNames('h-5 w-5 text-core-gray-900', {
        'h-5 w-5': size === 'sm',
        'h-8 w-8': size === 'lg',
      })}
    />
  </span>
)

const MediaGallery: React.FC<Props> = ({
  title = 'Media Gallery',
  description,
  items,
  campaign,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [initialItem, setInitialItem] = useState(0)
  const { modalIsOpen } = useAuth()

  const [
    galleryRef,
    { canScrollBack, canScrollForward, scrollBack, scrollForward },
  ] = useCarousel<HTMLDivElement>()

  const handleClick = useCallback((idx: number) => {
    setInitialItem(idx)
    setIsOpen(true)
  }, [])

  return (
    <>
      <div className={className}>
        {title && (
          <Type as="h2" variant="title-lg" className="mb-4 font-bold">
            {title}
          </Type>
        )}
        {description && <TextBlock document={description} className="mb-4" />}

        {items[0] && (
          <div className="mb-6 flex w-full flex-col gap-2">
            <ButtonBase
              onClick={() => handleClick(0)}
              className="relative overflow-hidden rounded-lg"
            >
              <Image
                image={items[0].image}
                className="aspect-video w-full rounded-lg object-cover"
                fit="fill"
                focus="face"
                lazy
              />
              {!!items[0].video && <PlayIcon size="lg" />}
            </ButtonBase>

            {items[0].showTitleOnThumbnail && (
              <Type as="h3" variant="title-md">
                {items[0].title}
              </Type>
            )}
          </div>
        )}
        <div className="relative">
          <div
            ref={galleryRef}
            className="-mr-6 hide-scrollbar flex snap-x snap-mandatory gap-4 overflow-x-scroll overscroll-x-contain"
          >
            {items.slice(1).map((item, idx) => {
              return (
                <ButtonBase
                  key={item.id}
                  onClick={() => handleClick(idx + 1)}
                  className="relative w-3/5 shrink-0 snap-start overflow-hidden rounded-lg last:mr-6 md:w-1/3"
                >
                  <Image
                    image={item.image}
                    className="aspect-video rounded-lg object-cover"
                    fit="fill"
                    focus="face"
                    lazy
                  />
                  {item.showTitleOnThumbnail && (
                    <div className="absolute bottom-0 left-0 right-0 z-10 bg-gradient-to-t from-[rgba(0,0,0,1)] to-[rgba(0,0,0,0)] p-2">
                      <Type
                        as="h3"
                        variant="eyebrow-md"
                        className="text-left text-white"
                      >
                        {item.title}
                      </Type>
                    </div>
                  )}
                  {!!item.video && <PlayIcon size="sm" />}
                </ButtonBase>
              )
            })}
          </div>
          <CarouselNav
            onClick={scrollBack}
            active={canScrollBack}
            orientation="horizontal"
            direction="back"
            className="top-0 p-2"
          />

          <CarouselNav
            onClick={scrollForward}
            active={canScrollForward}
            orientation="horizontal"
            direction="forward"
            className="top-0 p-2"
          />
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        zIndex="9999"
        disableAutoClose={modalIsOpen}
      >
        <MediaGalleryCarousel
          items={items}
          setIsOpen={setIsOpen}
          initialItem={initialItem}
          campaign={campaign}
        />
      </Modal>
    </>
  )
}

export default MediaGallery
