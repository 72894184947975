import { Livestream, getCampaignLivestreams } from '@/services/api/livestream'
import useApi from '../useApiV2'

const useLivestreams = (slug: string) => {
  const { data: livestreams, ...rest } = useApi<Livestream[]>({
    key: `livestreams/${slug}`,
    request: () => getCampaignLivestreams(slug),
  })

  return {
    livestreams,
    ...rest,
  }
}

export default useLivestreams
