import React from 'react'
import { OneColorLogoProps, scaleDimensions } from './utils'

export const GoogleTvLogo: React.FC<OneColorLogoProps> = ({
  height = 32,
  color = 'white',
  className,
}) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(
    height,
    47,
    18
  )
  const hex = color

  return (
    <svg
      viewBox="0 0 2896 500"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={hex}
      height={scaledHeight}
      width={scaledWidth}
      aria-label="GoogleTV"
    >
      <g fill={hex}>
        <path d="m2500 99.4v286.2h-41.7v-286.2h-91.4v-40.3h224.1v40.3z" />
        <path d="m2776.8 386.2h-43.8l-114.7-326.4h46.7l89.4 264.9h2l92.9-264.9h46.7z" />
        <path d="m960 393.2c-101.9 0-187.3-83-187.3-184.9s85.5-184.9 187.3-184.9c56.1 0 96.4 21.9 126.7 50.7l-35.8 35.8c-21.9-20.4-50.7-36.3-90.9-36.3-74.5 0-132.7 60.1-132.7 134.2 0 74.5 58.1 134.2 132.7 134.2 48.2 0 75.5-19.4 93.4-36.8 14.4-14.4 23.9-35.3 27.3-63.6h-120.7v-50.6h170c2 8.9 2.5 19.9 2.5 31.3 0 37.8-10.4 84.5-43.7 118.3-32.4 34.7-73.6 52.6-128.8 52.6z" />
        <path d="m1395.3 274.5c0 68.6-52.7 118.8-117.3 118.8s-117.3-50.7-117.3-118.8c0-69.1 52.7-118.8 117.3-118.8 64.6.1 117.3 49.7 117.3 118.8zm-51.2 0c0-42.7-30.3-72.1-66.1-72.1-35.3 0-66.1 29.3-66.1 72.1 0 42.2 30.3 72.1 66.1 72.1 35.3 0 66.1-29.8 66.1-72.1z" />
        <path d="m1658.2 274.4c0 68.6-52.7 118.8-117.3 118.8s-117.3-50.7-117.3-118.8c0-69.1 52.7-118.8 117.3-118.8 64.6-.5 117.3 49.7 117.3 118.8zm-51.2 0c0-42.7-30.3-72.1-66.1-72.1-35.3 0-66.1 29.3-66.1 72.1 0 42.2 30.3 72.1 66.1 72.1 35.3 0 66.1-29.9 66.1-72.1z" />
        <path d="m1914.6 162.6v213.7c0 88-51.7 123.7-113.3 123.7-57.6 0-92.4-38.8-105.3-70.1l45.7-18.9c7.9 19.4 27.8 42.2 60.1 42.2 39.3 0 63.6-24.3 63.6-70.1v-16.9h-2c-11.9 14.4-34.3 26.8-62.6 26.8-59.6 0-113.8-51.7-113.8-118.3 0-67.1 54.7-119.3 113.8-119.3 28.3 0 50.7 12.4 62.6 26.3h2v-19.4h49.2zm-46.2 112.3c0-41.7-27.8-72.6-63.6-72.6-36.3 0-66.1 30.8-66.1 72.6 0 41.2 30.3 71.6 66.1 71.6 35.7 0 63.6-30.4 63.6-71.6z" />
        <path d="m2006 36.5v349.8h-52.2v-349.8z" />
        <path d="m2211.7 313.7 40.7 26.8c-12.9 19.4-44.7 52.7-98.9 52.7-67.6 0-116.3-52.2-116.3-118.8 0-70.6 49.2-118.8 110.3-118.8 61.6 0 91.9 49.2 101.9 75.5l5.5 13.4-159 65.6c11.9 23.9 31.3 36.3 57.6 36.3 26.4.1 44.3-12.9 58.2-32.7zm-125.2-42.8 106.3-44.2c-6-14.9-23.4-25.3-44.2-25.3-26.3 0-63.1 23.3-62.1 69.5z" />
      </g>
      <path
        d="m497.4 0h-337.9c-29.3 0-53.2 23.9-53.2 53.2v53.2h411.9c29.3 0 53.2 23.9 53.2 53.2v-85.6c0-41.2-33.3-74-74-74z"
        fill="#4285f4"
      />
      <path
        d="m74 0c-40.7 0-74 33.3-74 74v183.4c0 29.3 23.9 53.2 53.2 53.2h53.2v-257.4c0-29.3 23.9-53.2 53.2-53.2z"
        fill="#34a853"
      />
      <path
        d="m74 416.9h337.9c29.3 0 53.2-23.9 53.2-53.2v-53.2h-411.9c-29.3.1-53.2-23.8-53.2-53.1v86c0 40.2 32.8 73.5 74 73.5z"
        fill="#fbbc04"
      />
      <path
        d="m497.4 416.9c40.7 0 74-33.3 74-74v-183.4c0-29.3-23.9-53.2-53.2-53.2h-53.2v257.4c0 29.3-23.8 53.2-53.2 53.2z"
        fill="#ea4335"
      />
    </svg>
  )
}
