import { useMemo } from 'react'
import { contentUtil, richText } from '@/utils'
import { ISectionContentBlockFields } from '../../../../types/generated/contentful'
import IconGroup from './IconGroup'

interface Props {
  content: ISectionContentBlockFields
}

const cyProps = ['backing', 'creation', 'returns']

const HowItWorks = ({ content }: Props) => {
  const items = useMemo(() => {
    if (!content?.items.length) return undefined

    return content.items
      .map(contentUtil.mapContent)
      .filter(contentUtil.filterEmpty)
  }, [content])

  if (!items?.length) return null

  return (
    <div className="rounded border border-core-gray-300">
      <div className="px-2 py-6 md:flex" data-cy="TESTING">
        {items.map((item, i) => (
          <IconGroup
            key={item.id}
            title={item.title}
            description={
              'text' in item && item.text ? richText(item.text) : undefined
            }
            dataCy={`how-it-works-${cyProps[i]}`}
            image={item?.image}
          />
        ))}
      </div>
    </div>
  )
}

export default HowItWorks
