import React from 'react'
import Image from 'next/image'
import { BaseLogoProps, scaleDimensions } from './utils'

export const GoogleStoreDownloadColorLogo: React.FC<BaseLogoProps> = ({
  height = 24,
  className,
}) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(
    height,
    83,
    24
  )

  return (
    <Image
      width={scaledWidth}
      height={scaledHeight}
      src={`https://images.angelstudios.com/image/upload/v1670262802/angel-studios/landing/google-play-badge.webp`}
      className={className}
      alt="Google Play Store Logo"
      aria-label="Get it on Google Play"
    />
  )
}
