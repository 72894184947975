import React from 'react'
import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { ISectionCastAndCrewFields } from 'src/types/generated/contentful'
import {
  CarouselNav,
  HeightTransitioner,
  TextBlock,
  Type,
} from '@/components/common'
import Image from '@/components/contentful/Image'
import useCarousel from '@/services/hooks/useCarousel'

const CastAndCrew: React.FC<ISectionCastAndCrewFields> = ({
  bios,
  intro,
  teamPortfolio,
}) => {
  const [
    carouselRef,
    { canScrollBack, canScrollForward, scrollBack, scrollForward },
  ] = useCarousel()

  return (
    <div>
      {intro && <TextBlock document={intro} className="pb-6" />}
      <Tab.Group>
        <div className="relative">
          <Tab.List
            ref={carouselRef}
            className="hide-scrollbar mb-8 -mr-6 flex gap-4 overflow-x-auto border-b border-core-gray-300"
          >
            {bios.map((bio) => (
              <Tab
                key={bio.sys.id}
                className={classNames(
                  'flex-shrink-0 border-b-4 border-transparent pb-4 outline-none last:mr-6 ui-selected:border-core-oxide-core'
                )}
              >
                {bio.fields.image && (
                  <Image
                    image={bio.fields.image}
                    height={120}
                    width={120}
                    focus="face"
                    fit="fill"
                    className="rounded-full"
                  />
                )}
              </Tab>
            ))}
          </Tab.List>
          <CarouselNav
            active={canScrollBack}
            direction="back"
            onClick={scrollBack}
            orientation="horizontal"
          />
          <CarouselNav
            active={canScrollForward}
            direction="forward"
            onClick={scrollForward}
            orientation="horizontal"
          />
        </div>
        <Tab.Panels className="mb-10">
          <HeightTransitioner>
            {bios.map((bio) => (
              <Tab.Panel key={`${bio.sys.id}-panel`}>
                <div className="mb-4">
                  <Type as="p" variant="title-lg" className="font-semibold">
                    {bio.fields.title}
                  </Type>
                  {bio.fields.subtitle && (
                    <Type
                      as="p"
                      variant="title-md"
                      className="mt-1 text-core-gray-600"
                    >
                      {bio.fields.subtitle}
                    </Type>
                  )}
                </div>
                {bio.fields.text && <TextBlock document={bio.fields.text} />}
              </Tab.Panel>
            ))}
          </HeightTransitioner>
        </Tab.Panels>
      </Tab.Group>
      {teamPortfolio && (
        <TextBlock
          document={teamPortfolio.fields.text}
          className="border-t border-core-gray-300 pt-10"
        />
      )}
    </div>
  )
}

export default CastAndCrew
