import React, { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { Asset } from 'contentful'
import NextImage from 'next/image'
import Loader from 'react-loader-spinner'
import { IMediaGalleryItemFields } from 'src/types/generated/contentful'
import { VideoBox } from '@/components/common'
import { VideoPlayer } from '@/components/common/VideoBox'
import Image from '@/components/contentful/Image'
import { Campaign } from '@/services/api/campaign'
import tailwind from '../../../../../../tailwind.config'
import MediaGalleryItemDetails from './MediaGalleryItemDetails'

export interface Props extends IMediaGalleryItemFields {
  containerWidth: number
  containerHeight: number
  isCurrent: boolean
  showDetails: boolean
  campaign: Campaign
  mouseHandlers: { onMouseOver: () => void; onMouseOut: () => void }
}

export const getDimensions = ({
  image,
  aspectRatio,
  containerWidth,
  containerHeight,
}: {
  image?: Asset
  aspectRatio?: number
  containerWidth: number
  containerHeight: number
}) => {
  const { width = 1, height = 1 } = image?.fields.file.details.image || {}

  const r = aspectRatio || width / height
  const isLandscape = r >= 1

  return {
    width: isLandscape ? containerWidth : containerHeight * r,
    height: isLandscape ? containerWidth / r : containerHeight,
  }
}

const MediaGalleryItem: React.FC<Props> = ({
  image,
  useBlurredImageBackground,
  video,
  title,
  caption,
  containerWidth,
  containerHeight,
  isCurrent,
  showDetails,
  campaign,
  mouseHandlers,
}) => {
  const [loaded, setLoaded] = useState(false)
  const [videoPaused, setVideoPaused] = useState(false)
  const player = useRef<VideoPlayer>(null)
  const isCurrentRef = useRef(isCurrent)

  const { width, height } = useMemo(
    () =>
      getDimensions({
        image,
        aspectRatio: video?.fields.aspectRatio,
        containerWidth,
        containerHeight,
      }),
    [image, video, containerWidth, containerHeight]
  )

  useEffect(() => {
    isCurrentRef.current = isCurrent
    if (isCurrent) {
      player.current?.play()
    } else {
      player.current?.pause()
    }
  }, [isCurrent])

  return (
    <div className="relative flex h-full w-full shrink-0 snap-center snap-always items-center justify-center overflow-hidden">
      {useBlurredImageBackground && (
        <div className="absolute inset-0 -m-6">
          <NextImage
            src={`https:${image.fields.file.url}?h=100&w=100&q=50`}
            width={100}
            height={100}
            className={classNames(
              'pointer-events-none h-full w-full object-cover opacity-25 transition-opacity duration-500 ease-in-out',
              { 'opacity-0': !loaded }
            )}
            alt="blurred image background"
            style={{ filter: 'blur(20px)' }}
          />
        </div>
      )}

      <div className={classNames('relative z-10', { 'w-full': !!video })}>
        {video && (
          <VideoBox
            ref={player}
            id={`media-gallery-${video.fields.id}`}
            allow="autoplay"
            campaignSlug={campaign.slug}
            video={video.fields.id}
            lazyload
            aspectRatio={video.fields.aspectRatio}
            service={video.fields.service}
            className={classNames(
              'w-full transition-opacity delay-500 duration-500',
              { 'opacity-0': !loaded, 'opacity-50': videoPaused }
            )}
            onPlaying={() => {
              setVideoPaused(false)
            }}
            onPaused={() => {
              setVideoPaused(true)
              // remove focus from iframe element so modal keyboard controls are active
              const iframe = document.activeElement as HTMLIFrameElement
              iframe?.blur?.()
            }}
            onReady={() => {
              setLoaded(true)
              if (isCurrentRef.current) {
                player.current?.play()
              }
            }}
            onEnded={() => {
              player.current?.play()
            }}
          />
        )}

        {!video && (
          <Image
            image={image}
            className={classNames(
              'transition-opacity duration-500 ease-in-out',
              { 'opacity-0': !loaded }
            )}
            width={width}
            height={height}
            fit="fill"
            focus="face"
            lazy
            onLoad={() => setLoaded(true)}
          />
        )}

        <MediaGalleryItemDetails
          campaign={campaign}
          title={title}
          caption={caption}
          className="p-4 text-white lg:hidden"
        />
      </div>

      <div
        className={classNames(
          'pointer-events-none absolute bottom-0 left-0 right-0 z-20 hidden p-4 pb-20 pt-48 text-white md:p-6 lg:block lg:p-8 lg:pb-8',
          'transition-opacity delay-500 duration-500 ease-in-out',
          'bg-gradient-to-t from-[rgba(0,0,0,1)] to-[rgba(0,0,0,0)]',
          { 'opacity-0': video ? !videoPaused : !showDetails }
        )}
        {...mouseHandlers}
      >
        <MediaGalleryItemDetails
          campaign={campaign}
          title={title}
          caption={caption}
          className={classNames(
            'pointer-events-auto transition delay-500 duration-500',
            { 'translate-y-full': video ? !videoPaused : !showDetails }
          )}
        />
      </div>

      {!loaded && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader
            type="TailSpin"
            color={tailwind.theme.colors.oxide['3']}
            height={50}
            width={50}
          />
        </div>
      )}
    </div>
  )
}

export default MediaGalleryItem
