import React, { useMemo } from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import { useExperiment } from '@/experimentation'
import useHydration from '@/services/hooks/useHydration'
import useWindowSize from '@/services/hooks/useWindowSize'
import Type from '../Type'
import { AppleStoreDownloadLogo } from './AppleStoreDownloadLogo'
import { AppleTvDownloadLogo } from './AppleTvDownloadLogo'
import { FireTVLogo } from './FireTVLogo'
import { GoogleStoreDownloadColorLogo } from './GoogleStoreDownloadColorLogo'
import { GoogleTvLogo } from './GoogleTvLogo'
import { LgTvLogo } from './LgTvLogo'
import { MicrosoftXboxTextLogo } from './MicrosoftXboxTextLogo'
import { RokuLogo } from './RokuLogo'
import { SamsungTvLogo } from './SamsungTVLogo'
import { baseLogoHeight, paths } from './utils'

interface AppDownloadLinksProps {
  allowWrap?: boolean
  androidDownload?: string
  iosDownload?: string
  fireTvDownload?: string
  appleTvDownload?: string
  rokuDownload?: string
  googleTvDownload?: string
  samsungTvDownload?: string
  lgTvDownload?: string
  microsoftDownload?: string
  layout?: 'loose' | 'tight'
  className?: string
}

export const AppDownloadLinks: React.FC<AppDownloadLinksProps> = ({
  allowWrap,
  androidDownload = paths.googlePlayStore,
  iosDownload = paths.appleAppStore,
  fireTvDownload = paths.fireTv,
  appleTvDownload = paths.appleTv,
  rokuDownload = paths.roku,
  googleTvDownload = paths.googleTv,
  samsungTvDownload = paths.samsungTv,
  lgTvDownload = paths.lgTv,
  microsoftDownload = paths.microsoft,
  layout = 'loose',
  className,
}) => {
  const { isHydrated } = useHydration()
  const { innerWidth } = useWindowSize()
  const imgWidth = useMemo(() => Math.min(innerWidth, 700), [innerWidth])

  const [inExperiment] = useExperiment('offering_app_download_links', false)
  if (!isHydrated || !inExperiment) return null

  return (
    <div className={className}>
      <div className="flex flex-col items-center">
        <Image
          width={imgWidth}
          height={(imgWidth * 1243) / 1684}
          src={`https://images.angelstudios.com/image/upload/v1725034202/angel-funding/ui-images/qxaydflzgkhdyevez4rt.png`}
          alt="Watch anywhere"
        />

        <Type
          variant="heading-lg"
          as="h2"
          className="text-center font-semibold mb-8"
        >
          Watch Anytime.
          <br />
          Anywhere.
        </Type>

        <div
          className={classNames(
            'flex flex-col items-center justify-center gap-4 w-full',
            {
              'gap-y-8 md:gap-x-16': layout === 'loose',
            }
          )}
        >
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              'gap-y-8 md:gap-x-6 lg:gap-x-2 xl:gap-x-8': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={iosDownload}
            >
              <AppleStoreDownloadLogo
                className="min-w-[84px] shrink-0"
                height={baseLogoHeight - 1}
              />
            </a>
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={androidDownload}
            >
              <GoogleStoreDownloadColorLogo
                className="min-w-[84px] shrink-0"
                height={baseLogoHeight - 1}
              />
            </a>
          </div>
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              ' gap-y-8 md:gap-x-6 lg:gap-x-2 xl:gap-x-8': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              className="min-w-[70px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105 xl:min-w-[84px]"
              href={fireTvDownload}
            >
              <FireTVLogo
                className="min-w-[70px] xl:min-w-[84px]"
                color={'black'}
                height={baseLogoHeight}
              />
            </a>
            <a
              target="_blank"
              className="min-w-[60px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={microsoftDownload}
            >
              <MicrosoftXboxTextLogo
                className="min-w-[60px]"
                color={'core-gray-950'}
                height={baseLogoHeight - 2}
              />
            </a>
            <a
              target="_blank"
              className="min-w-[70px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105 xl:min-w-[84px]"
              href={rokuDownload}
            >
              <RokuLogo
                className="min-w-[70px] xl:min-w-[84px]"
                color={'black'}
                height={baseLogoHeight - 10}
              />
            </a>
          </div>
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              'gap-y-8 md:gap-x-6 lg:gap-x-2 xl:gap-x-8': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={lgTvDownload}
            >
              <LgTvLogo
                className="min-w-[84px]"
                color={'core-gray-950'}
                height={baseLogoHeight}
              />
            </a>
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={samsungTvDownload}
            >
              <SamsungTvLogo
                className="min-w-[84px]"
                color={'black'}
                height={baseLogoHeight + 5}
              />
            </a>
          </div>
          <div
            className={classNames('flex items-center justify-center gap-4', {
              'flex-wrap': allowWrap,
              'gap-y-8 md:gap-x-6 lg:gap-x-2 xl:gap-x-8': layout === 'loose',
            })}
          >
            <a
              target="_blank"
              className="min-w-[84px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105"
              href={googleTvDownload}
            >
              <GoogleTvLogo
                className="min-w-[84px]"
                color={'black'}
                height={baseLogoHeight + 10}
              />
            </a>
            <a
              target="_blank"
              className="min-w-[70px] shrink-0 transform-gpu transition-all duration-300 ease-in-out hover:scale-105 xl:min-w-[84px]"
              href={appleTvDownload}
            >
              <AppleTvDownloadLogo
                className="min-w-[70px] xl:min-w-[84px]"
                color={'black'}
                height={baseLogoHeight + 5.5}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
