import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import { IPageCampaign, IVideoBlock } from 'src/types/generated/contentful'
import Markdown from '@/components/Markdown'
import {
  AnimateIn,
  CampaignShareBox,
  Type,
  VideoModal,
} from '@/components/common'
import { AppDownloadLinks } from '@/components/common/AppDownloadLinks/AppDownloadLinks'
import ExpandableType from '@/components/common/ExpandableType'
import { VideoService } from '@/components/common/VideoBox'
import { Experiment } from '@/components/contentful/ContentfulExperiment'
import Details from '@/components/pages/CampaignPages/Details'
import { DetailsProvider } from '@/components/pages/CampaignPages/DetailsContext'
import DetailsTabs from '@/components/pages/CampaignPages/DetailsTabs'
import InvestCard from '@/components/pages/CampaignPages/InvestCard'
import MobileInvestButtonWrapper from '@/components/pages/CampaignPages/InvestComponents/MobileInvestButtonWrapper'
import PriorRaisesInvestCard from '@/components/pages/CampaignPages/InvestComponents/PriorRaisesInvestCard'
import PublicComments from '@/components/pages/CampaignPages/PublicComments'
import VideoHero from '@/components/pages/CampaignPages/VideoHero'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import fbPixel, { getCampaignPixels } from '@/services/analytics/fbPixel'
import { Campaign } from '@/services/api/campaign'
import { SiteAssets } from '@/services/api/siteAssets'
import useLivestreams from '@/services/hooks/useLivestreams'
import { contentUtil } from '@/utils'

export interface Props {
  campaign: Campaign
  content?: IPageCampaign
  headerVideo: { fields: Experiment<IVideoBlock> }
  siteAssets: SiteAssets
}

const CampaignPage: React.FC<Props> = ({
  campaign,
  content,
  headerVideo,
  siteAssets,
}) => {
  const router = useRouter()
  const topInvestBtnRef = useRef(null)
  const { start_video: startVideo, start_video_service: startVideoService } =
    router.query
  const [modalVideo, setModalVideo] = useState<{
    id: string
    service?: VideoService
    aspectRatio?: number
  } | null>(null)
  const pageContent = content && contentUtil.mapContent(content)

  const { livestreams } = useLivestreams(campaign.slug)

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.dataLayer.push({
        event: 'landing-page',
        campaign: campaign.name,
        status: campaign.regulationType,
      })
    }
  }, [campaign])

  useEffect(() => {
    const serviceIsValid =
      typeof startVideoService === 'undefined' ||
      startVideoService === 'youtube' ||
      startVideoService === 'vimeo'
    if (typeof startVideo === 'string' && serviceIsValid) {
      setModalVideo({
        id: startVideo,
        service: startVideoService as VideoService,
      })
    }
  }, [startVideo, startVideoService])

  useEffect(() => {
    if (campaign) {
      fbPixel.init(getCampaignPixels(campaign))
      fbPixel.pageView()
      SegmentHandler.track(
        'Campaign Pageview',
        { campaign: campaign.name, project: campaign.slug },
        { suppressSegmentTrack: true }
      )
    }
  }, [campaign])

  return (
    <div className="min-h-screen">
      <DetailsProvider
        campaign={campaign}
        content={pageContent?.content}
        enableLegacyLivestreams={!livestreams?.length}
      >
        <VideoHero
          headerVideo={headerVideo.fields}
          campaign={campaign}
          setModalVideo={setModalVideo}
          modalIsOpen={!!modalVideo}
          anticipatedRegulation={pageContent?.anticipatedRegulation?.fields}
        />

        {campaign.slug === 'angel-2' && (
          <div className="p-3 pb-1 md:px-4 bg-core-gray-200">
            <ExpandableType lines={3} className="max-w-[1264px] mx-auto">
              <Type as="p" variant="caption-sm" className="text-core-gray-700">
                An investment in the Shares of our Class C Common Stock (“Class
                C Shares”) that we are currently offering under Regulation A of
                the Securities Act of 1933, is subject to significant risks,
                including the risk of complete loss of principal. You should
                only invest as much as you are willing and can afford to lose,
                and only after you have read and understood our offering
                documents (including the risk factors in our Offering Circular)
                linked below. In reviewing our statements, particularly those
                relating to our share prices, you should bear in mind that: The
                increase in the value of our shares is based on a comparison of
                our Class C Shares to the Class B shares that we sold in 2016.
                Existing investors in our Class C Shares and Class B shares have
                not realized any increased value of those shares. References to
                growth relate only to unrealized gains. The offering price of
                our Class C Shares was determined by the Company. There is no
                public market for our Class C Shares, and we do not expect any
                such market to develop. An investment in the Class C Shares will
                be highly illiquid. Therefore, it is unlikely that you will be
                able to realize an appreciation in the value of the Class C
                Shares, if any. Securities issued by our Company may have
                different values, and we have sold securities at different terms
                and prices to those we are currently offering. Past appreciation
                in the value of our shares does not guarantee that the value of
                such shares will increase in the future.
              </Type>
            </ExpandableType>
          </div>
        )}

        <InvestCard
          investButtonWrapperRef={topInvestBtnRef}
          campaign={campaign}
          className={[
            'mx-auto max-w-[640px] px-4 pb-5 pt-8',
            'md:rounded md:pb-8 md:pt-10',
            '2lg:hidden',
          ].join(' ')}
          sectionName="body"
          anticipatedRegulation={pageContent?.anticipatedRegulation?.fields}
        />

        {siteAssets.usePriorRaisesLayout && (
          <div className="mt-6 max-w-[960px] px-4 md:px-6 lg:mx-auto 2lg:hidden">
            <Markdown source={siteAssets.campaignSummary} />

            <PriorRaisesInvestCard
              investButtonWrapperRef={topInvestBtnRef}
              campaign={campaign}
              className="mt-6 rounded border border-core-gray-200 p-5 md:p-6"
            />
          </div>
        )}

        <DetailsTabs campaign={campaign} />

        <div className="my-6 flex h-full max-w-[1264px] flex-col-reverse lg:mx-auto lg:my-14 2lg:mx-8 2lg:grid 2lg:grid-cols-3 2lg:gap-8 min-[1328px]:mx-auto">
          <div className="flex flex-col 2lg:col-span-2">
            <Details />

            {campaign?.regulationType === 'CF' && (
              <PublicComments
                isOpen={campaign.status === 'currently_funding'}
                campaignName={campaign.name}
                campaignSlug={campaign.slug}
                className="relative mb-6 flex flex-col justify-center bg-core-white px-6 py-8 lg:mx-24 lg:rounded-2xl 2lg:mx-0"
              />
            )}

            {siteAssets.cognitoFormsId && siteAssets.cognitoFormsKey && (
              <AnimateIn
                variant={{
                  easing: 'ease-out',
                  from: 'opacity-0 translate-y-[25%]',
                  to: 'opacity-100 translate-y-0',
                }}
                className="mb-6 md:mb-8"
              >
                <iframe
                  id="whitelabel-contact-form"
                  src={`https://www.cognitoforms.com/f/${siteAssets.cognitoFormsKey}/${siteAssets.cognitoFormsId}`}
                  className="w-full border-0 bg-white p-6 pb-0 lg:rounded-2xl"
                  height="550"
                ></iframe>
              </AnimateIn>
            )}

            <CampaignShareBox
              campaign={campaign}
              className="mb-6 w-full lg:hidden"
            />

            {siteAssets.showAppDownloadLinks && (
              <AppDownloadLinks className="mb-6 md:rounded-2xl  p-5 md:p-8" />
            )}
          </div>

          <div className="hidden 2lg:block">
            <div className="sticky top-24">
              {siteAssets.usePriorRaisesLayout ? (
                <PriorRaisesInvestCard
                  campaign={campaign}
                  className="rounded-2xl border border-core-gray-200 bg-white p-6"
                />
              ) : (
                <InvestCard
                  campaign={campaign}
                  className="rounded-2xl border border-core-gray-200 bg-white p-6"
                  sectionName="sidebar"
                  anticipatedRegulation={
                    pageContent?.anticipatedRegulation?.fields
                  }
                />
              )}
            </div>
          </div>
        </div>

        <MobileInvestButtonWrapper
          campaign={campaign}
          color="oxide"
          sectionName="body"
          topInvestBtnRef={topInvestBtnRef}
        />
      </DetailsProvider>

      <VideoModal
        isOpen={!!modalVideo?.id}
        setIsOpen={(isOpen) => setModalVideo(isOpen ? modalVideo : null)}
        videoId={modalVideo?.id || ''}
        service={modalVideo?.service}
        campaignSlug={campaign.slug}
        aspectRatio={
          modalVideo?.aspectRatio ||
          headerVideo.fields.variations[0]?.fields.aspectRatio
        }
      />
    </div>
  )
}

export default CampaignPage
