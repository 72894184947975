import { IContentBlock } from 'src/types/generated/contentful'
import { AnimateIn } from '@/components/common'
import Image from '@/components/contentful/Image'
import { contentUtil, richText } from '@/utils'

interface Props {
  items?: IContentBlock[]
}

const Testimonials = ({ items = [] }: Props) => {
  const testimonials = items
    .map(contentUtil.mapContent<IContentBlock>)
    .filter(contentUtil.filterEmpty)

  return (
    <div>
      {testimonials.map(({ id, image, subtitle, text, title }) => (
        <AnimateIn variant="slideOver" className="group" key={id}>
          <div className="m-3 hidden items-center md:my-6 md:flex">
            {image && (
              <Image
                image={image}
                className="aspect-square rounded-md object-cover"
                containerClassName="flex-[2] group-odd:pr-8 group-even:pl-8 group-even:order-2"
                width={262}
                height={262}
                fit="fill"
                focus="face"
                lazy
              />
            )}
            <div style={{ flex: 6 }}>
              {text && (
                <div className="mb-2 text-lg italic">{richText(text)}</div>
              )}
              <div className="tracking-widest text-md w-full uppercase text-gray">
                {title}
              </div>
              {subtitle && (
                <div className="tracking-widest w-full text-xs uppercase text-gray">
                  {subtitle}
                </div>
              )}
            </div>
          </div>
          <div className="m-3 my-5 flex flex-col items-center text-center md:hidden">
            {image && (
              <Image
                image={image}
                width={160}
                height={160}
                fit="fill"
                focus="face"
                className="object-cover"
                containerClassName="rounded-full w-[160px] h-[160px] mb-4 overflow-hidden shadow-[0px_7px_10px_#C0C0C0]"
                lazy
              />
            )}
            <div style={{ flex: 6 }}>
              {text && (
                <div className="mb-2 text-lg italic">{richText(text)}</div>
              )}
              <div className="tracking-widest w-full text-sm uppercase text-gray">
                {title}
              </div>
              {subtitle && (
                <div className="tracking-widest w-full text-sm uppercase text-gray">
                  {subtitle}
                </div>
              )}
            </div>
          </div>
        </AnimateIn>
      ))}
    </div>
  )
}

export default Testimonials
