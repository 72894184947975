import React, { useState, createContext, useMemo, useContext } from 'react'
import {
  IFaqSection,
  IMediaGallery,
  IPageCampaignFields,
  ISectionContentBlock,
  ISectionContentBlockFields,
  ISectionLivestream,
} from 'src/types/generated/contentful'
import { Campaign } from '@/services/api/campaign'
import { contentUtil } from '@/utils'
import { CONTENT_BLOCK_LAYOUT } from '../../../../constants/contentful'
import Dynamic from '../../contentful/Dynamic'

interface Tab {
  title?: string
  linkText?: string
  id?: string
  visible?: boolean
  Component?: () => JSX.Element
}

interface Context {
  tabs: Tab[]
  selectedTab: number
  setTab: (tab: number) => void
}

const DetailsContext = createContext<Context>({
  tabs: [],
  selectedTab: 0,
  setTab: () => null,
})

const TAB_IDS = {
  faqSection: 'section-faq',
  mediaGallery: 'media-gallery',
  sectionCastAndCrew: 'section-cast-and-crew',
  [`sectionContentBlock${CONTENT_BLOCK_LAYOUT.CARD}`]: 'section-how-it-works',
  [`sectionContentBlock${CONTENT_BLOCK_LAYOUT.PERKS}`]: 'section-perks',
  sectionLivestream: 'section-livestream',
  sectionOffering: 'section-offering',
  sectionOverview: 'section-overview',
  sectionPerks: 'section-perks',
}

export const useDetailsContext = () => useContext(DetailsContext)

export interface DetailsProviderProps {
  campaign: Campaign
  content?: IPageCampaignFields['content']
  enableLegacyLivestreams: boolean
}

export const DetailsProvider: React.FC<DetailsProviderProps> = ({
  children,
  campaign,
  content = [],
  enableLegacyLivestreams,
}) => {
  const [tab, setTab] = useState(-1)

  const tabs = useMemo(() => {
    const allTabs = content
      .map((c, idx) => {
        const cmp = contentUtil.mapContent(c)
        if (!cmp) return { title: '', id: '' }

        let isVisible = true
        if (cmp.contentType === 'faqSection') {
          isVisible = !!(cmp as contentUtil.MappedContent<IFaqSection>).faqs
            ?.length
        } else if (cmp.contentType === 'mediaGallery') {
          isVisible = !!(cmp as contentUtil.MappedContent<IMediaGallery>).items
            ?.length
        } else if (cmp.contentType === 'sectionLivestream') {
          isVisible =
            !enableLegacyLivestreams ||
            (enableLegacyLivestreams &&
              !!(cmp as contentUtil.MappedContent<ISectionLivestream>)
                .livestreams?.length)
        } else if (
          // Have to cast here because 'perks' is legacy value not on current generated types
          (cmp as contentUtil.MappedContent<ISectionContentBlock>).layout ===
            (CONTENT_BLOCK_LAYOUT.PERKS as ISectionContentBlockFields['layout']) ||
          cmp.contentType === 'sectionPerks'
        ) {
          isVisible = !!campaign.perks.length
        }

        const key = cmp.contentType + ('layout' in cmp ? cmp.layout : '')
        const id = TAB_IDS[key as keyof typeof TAB_IDS] + cmp.id

        return {
          title:
            'title' in cmp && typeof cmp.title === 'string' ? cmp.title : '',
          linkText:
            'linkText' in cmp && typeof cmp.linkText === 'string'
              ? cmp.linkText
              : '',
          id,
          visible: isVisible,
          Component() {
            return (
              <Dynamic
                key={cmp.id}
                content={cmp}
                campaign={campaign}
                enableLegacyLivestreams={enableLegacyLivestreams}
                idx={idx}
              />
            )
          },
        }
      })
      .filter((t) => !!t.id)

    if (campaign.status === 'testing_the_waters') {
      return allTabs.filter(
        ({ id }) =>
          !id?.includes('section-offering') && !id?.includes('section-perks')
      )
    }

    return allTabs
  }, [campaign, content, enableLegacyLivestreams])

  return (
    <DetailsContext.Provider
      value={{
        tabs: tabs.filter(({ visible }) => visible),
        selectedTab: tab,
        setTab,
      }}
    >
      {children}
    </DetailsContext.Provider>
  )
}
