import axios from 'axios'

/* eslint-disable no-console */

const apiClient = axios.create({
  baseURL:
    process.env.NEXT_PUBLIC_CAMPAIGN_DETAILS_URL ||
    'https://campaign-details.angelstudios.com',
})

export default apiClient
