import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react'
import { FaPlay } from 'react-icons/fa'
import { Scrim, VideoBox } from '@/components/common'
import { VideoService, VideoPlayer } from '@/components/common/VideoBox'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { Campaign } from '@/services/api/campaign'
import useVideoBackgroundImage from '@/services/hooks/useVideoBackgroundImage'
import useWindowSize from '@/services/hooks/useWindowSize'
import tailwind from '../../../../../tailwind.config'
import { ISectionAnticipatedRegulationTypeFields } from '../../../../types/generated/contentful'
import InvestCard from '../InvestCard'

const VIDEO_BREAK = parseInt(tailwind.theme.screens.xl, 10)

export interface Props {
  video: string
  campaign: Campaign
  setModalVideo: (modalVideo: {
    id: string
    service: VideoService
    aspectRatio?: number
  }) => void
  modalIsOpen: boolean
  anticipatedRegulation?: ISectionAnticipatedRegulationTypeFields
  aspectRatio?: number
  service?: VideoService
}

const VideoHero: React.FC<Props> = ({
  video,
  campaign,
  setModalVideo,
  modalIsOpen,
  anticipatedRegulation,
  aspectRatio,
  service = 'youtube',
}) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { src: backgroundSrc } = useVideoBackgroundImage({
    video,
    size: 'sm',
    service,
  })
  const playerRef = useRef<VideoPlayer | null>(null)
  const id = `video-hero-${video}`

  const { innerWidth: winWidth } = useWindowSize()

  const handlePlayButtonClick = useCallback(() => {
    playerRef.current?.pause()
    setModalVideo({ id: video, service, aspectRatio })

    SegmentHandler.track('On Tap', {
      location: `/${campaign.slug}`,
      project: campaign.slug,
      section_name: 'hero',
      element_name: 'cta',
      value: 'Play Video',
      action_type: 'tap',
    })
  }, [setModalVideo, campaign, video, service, aspectRatio])

  const onReady = useCallback(() => {
    if (service === 'youtube') playerRef.current?.play()
    if (service === 'vimeo') setIsLoaded(true)
  }, [service])

  const onPlaying = useCallback(() => {
    if (service === 'youtube') setIsLoaded(true)
  }, [service])

  const onEnded = useCallback(() => playerRef.current?.play(), [])

  const offsets = useMemo(() => {
    const availableSpace = Math.max(0, (winWidth - VIDEO_BREAK) / 2)
    const videoOffset = availableSpace > 275 ? 150 : availableSpace

    return {
      video: videoOffset * -1,
      scrimStop: availableSpace + videoOffset,
    }
  }, [winWidth])

  useEffect(() => {
    if (!isLoaded) return
    if (modalIsOpen) playerRef.current?.pause()
    if (!modalIsOpen) playerRef.current?.play()
  }, [modalIsOpen, isLoaded])

  return (
    <div className="relative bg-black">
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute"
          style={{ top: -20, bottom: -20, left: -20, right: -20 }}
        >
          {backgroundSrc && (
            <img
              src={backgroundSrc}
              className="object-cover"
              style={{
                width: '100%',
                height: '100%',
                filter: 'blur(8px)',
                margin: -5,
              }}
            />
          )}
        </div>
      </div>

      <div
        className="relative mx-auto flex items-center bg-black"
        style={{
          maxWidth: VIDEO_BREAK,
          transform: `translateX(${offsets.video}px)`,
          opacity: isLoaded ? 1 : 0,
          transition: 'opacity 0.3s ease-in-out',
        }}
      >
        <VideoBox
          id={id}
          video={video}
          lazyload={false}
          fadeInOnLoad={false}
          className="pointer-events-none w-full"
          campaignSlug={campaign.slug}
          doSegmentTracking={false}
          aspectRatio={aspectRatio}
          params={
            service === 'youtube'
              ? { mute: 1, controls: 0 }
              : { background: 1, mute: 1, controls: 0 }
          }
          ref={playerRef}
          onReady={onReady}
          onPlaying={onPlaying}
          onEnded={onEnded}
          service={service}
          useThumbnailBackground={false}
        />
      </div>

      <Scrim
        startColor="rgba(0,0,0,1)"
        startColorStop={`${offsets.scrimStop}px`}
        position="right"
        distance="50%"
        className="hidden xl:block"
      />

      <div
        className="absolute inset-0 z-10 hidden xl:block"
        style={{ top: '50%', transform: 'translateY(-50%)' }}
      >
        <div
          className="relative mx-auto flex h-full w-full items-center justify-end"
          style={{ maxWidth: VIDEO_BREAK }}
        >
          <div className="max-w-[460px]">
            <InvestCard
              campaign={campaign}
              theme="light"
              className="mt-6 px-6"
              sectionName="hero"
              anticipatedRegulation={anticipatedRegulation}
              investButtonWrapperRef={null}
            />
          </div>
        </div>
      </div>

      <div
        className="absolute z-20 hidden transition xl:block"
        style={{
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          marginLeft: `${offsets.video}px`,
        }}
      >
        <button
          className="block border-0 text-white opacity-50 outline-none transition-opacity hover:opacity-100 focus:outline-none"
          style={{
            fontSize: 90,
          }}
          onClick={handlePlayButtonClick}
          aria-label="Play Video"
        >
          <FaPlay />
        </button>
      </div>
    </div>
  )
}

export default VideoHero
