import classNames from 'classnames'
import { IContentBlock } from 'src/types/generated/contentful'
import { AnimateIn, Text, TextBlock } from '@/components/common'
import Image from '@/components/contentful/Image'
import { contentUtil } from '@/utils'

interface Props {
  items: IContentBlock[]
}

const Bios = ({ items = [] }: Props) => {
  const bios = items
    .map(contentUtil.mapContent<IContentBlock>)
    .filter(contentUtil.filterEmpty)

  return (
    <div>
      {bios.map(({ id, image, subtitle, text, title: name }) => {
        const { width = 1, height = 1 } = image?.fields.file.details.image || {}
        const isLandscape = width / height > 1

        return (
          <AnimateIn
            key={id}
            variant="slideOver"
            className="my-10 flex flex-col md:mb-5 md:flex-row"
          >
            {image && (
              <Image
                image={image}
                width={130}
                height={isLandscape ? 130 : undefined} // For some reason need this if image is landscape
                focus="face"
                fit="fill"
                quality={100}
                containerClassName="self-center md:self-start mb-4 mr-5 w-[130px] h-[130px] overflow-hidden rounded-full shadow-[0px_7px_8px_#C0C0C0]"
                className={classNames('object-cover object-center', {
                  'h-full w-auto max-w-none': isLandscape,
                })}
              />
            )}

            <div className="flex flex-col md:ml-3 md:w-[78%]">
              <Text
                as="p"
                preset="heading.lg"
                className="mb-2 text-center font-normal text-gray md:text-left"
              >
                {name}
              </Text>
              {subtitle && (
                <Text
                  as="p"
                  preset="body.lg"
                  className="mb-2 text-center italic md:text-left"
                >
                  {subtitle}
                </Text>
              )}
              {text && <TextBlock document={text} />}
            </div>
          </AnimateIn>
        )
      })}
    </div>
  )
}

export default Bios
