import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import useOnScreen from '@/services/hooks/useOnScreen'
import InvestButton, { Props as InvestButtonProps } from './InvestButton'

export type Props = InvestButtonProps & {
  topInvestBtnRef: React.RefObject<Element>
}

const MobileInvestButtonWrapper: React.FC<Props> = ({
  campaign,
  topInvestBtnRef,
  ...investButtonProps
}) => {
  const ref = useRef(null)
  const isVisible = useOnScreen(ref)
  const topCtaIsVisible = useOnScreen(topInvestBtnRef)

  useEffect(() => {
    if (!document) return

    document.documentElement.style.setProperty(
      '--zendesk-bottom-position',
      !isVisible || topCtaIsVisible ? '0.75rem' : '5.5rem'
    )
  }, [isVisible, topCtaIsVisible])

  return (
    <div
      className={classNames(
        'sticky bottom-0 left-0 right-0 z-50 bg-white px-6 py-4 text-center lg:hidden',
        {
          hidden: topCtaIsVisible,
        }
      )}
      style={{ boxShadow: '0px -8px 8px rgba(106, 115, 129, 0.12)' }}
      ref={ref}
    >
      <InvestButton
        {...investButtonProps}
        campaign={campaign}
        className="w-full max-w-[420px] text-sm"
        rounded
        size={'md'}
      />
    </div>
  )
}

export default MobileInvestButtonWrapper
