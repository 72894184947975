import React from 'react'
import classNames from 'classnames'
import { Text } from '@/components/common'
import { formatMoney } from '@/utils'

export interface Props {
  title: string
  color: string
  amount: number
  isLast: boolean
}

const InvestmentBreakdownItem: React.FC<Props> = ({
  title,
  color,
  amount,
  isLast,
}) => {
  return (
    <div
      className={classNames('flex items-start border-b border-gray-8 p-4', {
        'border-b-0': isLast,
      })}
    >
      <div
        className="mt-1.5 h-3 w-3 rounded-full"
        style={{ backgroundColor: color }}
      />
      <div className="flex-1 pl-4 md:flex">
        <Text as="div" preset="body.lg" className="font-semibold">
          {title}
        </Text>
        <Text as="div" preset="body.lg" className="font-semibold md:ml-auto">
          {formatMoney(amount, {
            zeroFractionDigits: true,
          })}
        </Text>
      </div>
    </div>
  )
}

export default InvestmentBreakdownItem
