import {
  IFaqSection,
  IMediaGallery,
  IPageCampaignFields,
  ISectionCastAndCrewFields,
  ISectionContentBlock,
  ISectionContentBlockFields,
  ISectionLivestream,
  ISectionOffering,
  ISectionOverview,
  IVideoBlock,
} from 'src/types/generated/contentful'
import CastAndCrew from '@/components/pages/CampaignPages/CastAndCrew'
import MediaGallery from '@/components/pages/CampaignPages/DetailComponents/MediaGallery'
import Overview from '@/components/pages/CampaignPages/DetailComponents/Overview'
import FAQ from '@/components/pages/CampaignPages/FAQ'
import HowItWorks from '@/components/pages/CampaignPages/HowItWorks'
import {
  LegacyLivestreams,
  Livestreams,
} from '@/components/pages/CampaignPages/Livestreams'
import Offering from '@/components/pages/CampaignPages/Offering'
import Perks from '@/components/pages/CampaignPages/Perks'
import { Campaign as ICampaign } from '@/services/api/campaign'
import { contentUtil } from '@/utils'
import { CONTENT_BLOCK_LAYOUT } from '../../../../constants/contentful'

interface Props {
  campaign: ICampaign
  content: contentUtil.MappedContent<IPageCampaignFields['content'][number]>
  enableLegacyLivestreams: boolean
  idx: number
}

const Dynamic = ({
  campaign,
  content,
  enableLegacyLivestreams,
  idx,
}: Props) => {
  switch (content.contentType) {
    case 'faqSection': {
      const item = content as contentUtil.MappedContent<IFaqSection>
      const faqs = {
        questions: item?.faqs?.length
          ? item.faqs
              .map(contentUtil.mapContent)
              .filter(contentUtil.filterEmpty)
          : [],
      }
      return <FAQ faq={faqs} />
    }
    case 'mediaGallery': {
      const item = content as contentUtil.MappedContent<IMediaGallery>
      return (
        <MediaGallery
          campaign={campaign}
          title=""
          description={item.description}
          items={item.items
            .map(contentUtil.mapContent)
            .filter(contentUtil.filterEmpty)}
        />
      )
    }
    case 'sectionCastAndCrew':
      return (
        <CastAndCrew {...(content as unknown as ISectionCastAndCrewFields)} />
      )
    case 'sectionContentBlock': {
      const item = content as contentUtil.MappedContent<ISectionContentBlock>

      if (item.layout === CONTENT_BLOCK_LAYOUT.CARD) {
        return <HowItWorks content={item} />
      } else if (
        item.layout ===
        // Have to cast here because 'perks' is legacy value not on current generated types
        (CONTENT_BLOCK_LAYOUT.PERKS as ISectionContentBlockFields['layout'])
      ) {
        return <Perks perks={campaign.perks} />
      }
      break
    }
    case 'sectionOffering': {
      const item = content as contentUtil.MappedContent<ISectionOffering>
      const offerings = item?.links?.length
        ? item.links.map(contentUtil.mapContent).filter(contentUtil.filterEmpty)
        : []
      return <Offering campaignSlug={campaign.slug} offerings={offerings} />
    }
    case 'sectionOverview': {
      const item = content as contentUtil.MappedContent<ISectionOverview>
      return <Overview campaign={campaign} section={item} idx={idx} />
    }
    case 'sectionPerks':
      return <Perks perks={campaign.perks} />
    case 'sectionLivestream': {
      if (!enableLegacyLivestreams) {
        return (
          <Livestreams
            campaignName={campaign.name}
            campaignSlug={campaign.slug}
          />
        )
      }

      const item = content as contentUtil.MappedContent<ISectionLivestream>
      const livestreams = item.livestreams?.length
        ? item.livestreams
            // remove livestreams that don't have fields (i.e. in draft mode)
            .filter((ls: IVideoBlock) => ls.fields?.id && ls.fields?.title)
        : []

      return (
        <LegacyLivestreams
          campaignSlug={campaign.slug}
          id={content.id}
          livestreams={livestreams}
        />
      )
    }
    default:
      break
  }
  return null
}

export default Dynamic
