import { useInlineExperiment } from '@/experimentation'
import useHydration from '@/services/hooks/useHydration'
import { Experiment, Variation } from './types'

function ContentfulExperiment<T extends Variation>({
  children,
  experimentTitle,
  fallback = null,
  variations,
}: Experiment<T>) {
  const variationIds = variations.map((variation) => variation.sys?.id)
  const selectedVariationId = useInlineExperiment({
    key: experimentTitle,
    variations: variationIds as [string, string, ...string[]],
    active: variations.length > 1,
  })

  // Avoid static-rendering/hydration issues by not rendering until after app is hydrated
  const { isHydrated } = useHydration({ waitForAnimationFrame: true })

  if (!isHydrated) {
    return fallback
  }

  // Handle no variations
  if (variations.length === 0) return null

  // Handle only one variation
  if (variations.length === 1) {
    return children(variations[0])
  }

  // Handle selected variation
  const selectedEntry = variations.find(
    ({ sys }) => sys.id === selectedVariationId
  )
  if (selectedEntry) {
    return children(selectedEntry)
  }

  return null
}

export default ContentfulExperiment
