import React, { useState, useEffect, useCallback } from 'react'
import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import { Campaign } from '@/services/api/campaign'
import useCampaignShare from '@/services/hooks/useCampaignShare'
import useMeasure from '@/services/hooks/useMeasure'
import { scrollUtil } from '@/utils'
import { useDetailsContext } from './DetailsContext'

interface Props {
  campaign: Campaign
}

const DetailsTabs: React.FC<Props> = ({ campaign }) => {
  const { tabs, selectedTab, setTab } = useDetailsContext()
  const [tabsOverflowing, setTabsOverflowing] = useState(false)
  const [viewedSections, setViewedSections] = useState<string[]>([])
  const router = useRouter()

  // Handle selecting a tab
  const handleChange = useCallback(
    (idx) => {
      const el = document.querySelector<HTMLElement>(
        `#${tabs[idx].id}[data-title="${tabs[idx].title}"]`
      )
      const margin = idx === 0 ? 80 : 52 // 40px height of tabs + 12px
      scrollUtil.scrollToEl(el, margin, 'smooth')
      // Need a small timeout to override auto-setting of tabs
      setTimeout(() => {
        setTab(idx)
      }, 100)
    },
    [tabs, setTab]
  )

  const handleClick = useCallback(
    (idx) => {
      if (!tabs[idx]) return

      SegmentHandler.track('On Tap', {
        project: campaign.slug,
        location: router.asPath,
        section_name: 'body',
        element_name: 'menu_item',
        value: tabs[idx].title,
        action_type: 'tap',
      })
      handleChange(idx)
    },
    [handleChange, tabs, campaign, router.asPath]
  )

  // Set overflow state when the container is resized
  const [containerRef, containerDimensions] = useMeasure<HTMLDivElement>()
  useEffect(() => {
    // TODO: eventually Headless UI should allows us to get this using a forwarded ref
    const tabsEl = document.querySelector('.tab-container > div')
    setTabsOverflowing(
      tabsEl ? tabsEl.scrollWidth > Number(containerDimensions.width) : false
    )
  }, [containerDimensions.width])

  // Scroll selected tab into view if it's offscreen
  useEffect(() => {
    // TODO: eventually Headless UI should allows us to get this using a forwarded ref
    const tabEl = document.querySelector(
      '.tab-container button.border-core-oxide-core'
    )
    if (!tabEl || window.scrollY === 0) return // abort if no selected tab or user hasn't started scrolling
    const { left, right } = containerDimensions
    const { left: tabLeft, right: tabRight } = tabEl.getBoundingClientRect()

    if (tabLeft < Number(left) || tabRight > Number(right)) {
      tabEl.scrollIntoView()
    }
  }, [selectedTab, containerDimensions.width, containerDimensions])

  useEffect(() => {
    const section = tabs[selectedTab]?.title
    if (!section || viewedSections.includes(section)) return

    setViewedSections((cur) => [...cur, section])

    SegmentHandler.track('Section Viewed', {
      project: campaign.slug,
      location: router.asPath,
      section_name: 'body',
      value: section,
      action_type: 'scroll',
    })
  }, [selectedTab, tabs, campaign, router.asPath, viewedSections])

  const shareItems = useCampaignShare({ campaign })

  return (
    <div
      ref={containerRef}
      className="tab-container sticky top-0 z-50 bg-core-gray-100 pt-1 shadow-light"
    >
      <Tab.Group
        onChange={handleClick}
        selectedIndex={selectedTab}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        className={classNames(
          'hide-scrollbar flex w-full whitespace-nowrap px-6 lg:justify-center 2lg:max-w-[1264px] 2lg:justify-start 2lg:px-8 min-[1328px]:mx-auto min-[1328px]:px-0',
          {
            'overflow-x-auto': tabsOverflowing,
          }
        )}
      >
        <Tab.List>
          {tabs.map(({ linkText, title }, idx) => (
            <Tab
              key={title}
              data-cy="page-nav-tabs"
              className={classNames({
                'border-b-[3px] px-4 py-2 text-sm transition-colors focus:outline-none':
                  true,
                'border-transparent text-core-gray-700': idx !== selectedTab,
                'border-core-oxide-core font-bold text-core-oxide-core':
                  idx === selectedTab,
              })}
            >
              {linkText || title}
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>

      <div className="absolute bottom-0 right-0 top-0 hidden items-center px-8 xl:flex">
        {shareItems.map(({ Icon, href, label }, idx) => (
          <a
            key={idx}
            href={href}
            title={`Share on ${label}`}
            target="_blank"
            rel="noreferrer"
            className={idx === shareItems.length - 1 ? '' : 'mr-2'}
          >
            <Icon width={35} height={35} />
          </a>
        ))}
      </div>
    </div>
  )
}

export default DetailsTabs
