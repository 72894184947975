import React, { useEffect, useRef, useCallback } from 'react'
import ComponentWrap from './DetailComponents/ComponentWrap'
import { useDetailsContext } from './DetailsContext'

const Details = () => {
  const { tabs, setTab } = useDetailsContext()
  const observer = useRef<IntersectionObserver>()
  const itemsRef = useRef<HTMLElement[]>([])
  const lastScrollY = useRef(0)
  const ranOnce = useRef(false)

  const setNextTab = useCallback(
    (id, dir) => {
      const idx = tabs.findIndex((tab) => tab.id === id)
      const nextTab = tabs[idx + dir]
      if (nextTab) {
        setTab(idx + dir)
      }
    },
    [tabs, setTab]
  )

  const handler = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      // If no tab entering or exiting, set initial tab
      if (!ranOnce.current) {
        // Set initial tab to whichever one is most in viewport
        const sorted = entries.sort(
          (a, b) => b.intersectionRatio - a.intersectionRatio
        )
        setNextTab(sorted[0].target.id, 0)
        ranOnce.current = true
        return
      }

      const scrollingDown = window.scrollY > lastScrollY.current
      lastScrollY.current = window.scrollY

      // Get all entries that have either entered or exited the viewport
      // using the `isIntersecting` attr. Also check the `top` attr, which we
      // can use to determine if the element entered/exited from either top or
      // the bottom of the screen. This is useful to avoid tab shifts when content
      // in the current tab expands, e.g., in an Accordion
      const entering = entries.filter(
        ({ isIntersecting, boundingClientRect }) =>
          isIntersecting && boundingClientRect.top < 0
      )
      const exiting = entries.filter(
        ({ isIntersecting, boundingClientRect }) =>
          !isIntersecting && boundingClientRect.top < 0
      )

      // When scrolling up, set next tab as soon as new section in view
      if (!scrollingDown && entering.length) {
        setNextTab(entering[0].target.id, 0)
      }

      // Else when scrolling down, set next tab selected when current tab out of view
      if (scrollingDown && exiting.length) {
        setNextTab(exiting[exiting.length - 1].target.id, 1)
      }
    },
    [setNextTab]
  )

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, tabs.length)
  }, [tabs])

  useEffect(() => {
    if (observer.current) {
      return
    }

    observer.current = new IntersectionObserver(handler, {
      rootMargin: '-40px', // height of tabs
    })

    itemsRef.current.forEach((el) => {
      observer.current?.observe(el)
    })
  }, [tabs, handler])

  return (
    // Wrapped in fragment to prevent TS lint error where component is called
    <>
      {tabs.map((tab, idx) => (
        <ComponentWrap key={tab.id} idx={idx} {...tab} itemsRef={itemsRef} />
      ))}
    </>
  )
}

export default Details
