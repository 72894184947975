import React from 'react'
import Link from 'next/link'
import { Button, Type } from '@/components/common'

interface Props {
  campaignName: string
  campaignSlug: string
}

const Livestream: React.FC<Props> = ({ campaignName, campaignSlug }) => {
  return (
    <div className="flex flex-col gap-6 md:flex-row md:justify-between">
      <Type as="p" variant="paragraph-md" className="max-w-md">
        Hear from the filmmakers and learn about ‘{campaignName}’ progress and
        exciting news.
      </Type>
      <Link href={`/${campaignSlug}/livestream`} className="flex-shrink-0">
        <Button color="black">Go to livestreams</Button>
      </Link>
    </div>
  )
}

export default Livestream
