import React from 'react'
import { OneColorLogoProps, scaleDimensions } from './utils'

export const FireTVLogo: React.FC<OneColorLogoProps> = ({
  height = 24,
  color = '#141414',
  className,
}) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(
    height,
    46,
    24
  )
  const hex = color

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 47 26"
      fill="none"
      width={scaledWidth}
      height={scaledHeight}
      aria-label="FireTV"
    >
      <path
        d="M3.57332 15.1421C3.143 15.1615 2.71269 15.1615 2.28237 15.1518C2.10633 15.1421 1.94985 14.9387 1.94985 14.774C1.96941 12.1872 1.94985 9.6004 1.95963 7.01362C1.4902 6.99424 1.03054 6.99424 0.561105 6.99424C0.375286 6.99424 0.277487 6.88767 0.150348 6.77141C0.150348 6.50013 0.130788 6.22886 0.179688 5.95759C0.306827 5.65725 1.62711 5.57974 1.95963 5.5313C1.80315 3.73896 1.88139 1.24906 4.14055 0.890593C5.01096 0.754956 5.9205 0.793709 6.75179 1.10374C7.02563 1.34594 6.95717 1.78192 6.94739 2.12101C6.93761 2.27602 6.83981 2.34384 6.72245 2.43104C6.67355 2.44072 6.62465 2.44073 6.57575 2.44073C5.85204 2.31478 4.79581 2.17914 4.18945 2.69262C3.70046 3.10922 3.83737 4.86281 3.84715 5.50224C4.70779 5.50224 5.56842 5.50224 6.42905 5.50224C6.60509 5.50224 6.71267 5.60881 6.83003 5.71538C6.83003 6.03509 6.83981 6.34512 6.83003 6.66484C6.83003 6.83923 6.59531 6.99424 6.42905 6.98455C5.56842 6.97486 4.70779 6.98455 3.83738 6.98455C3.83738 9.57133 3.82759 12.1678 3.84715 14.7546C3.84715 14.8805 3.73958 15.0356 3.65156 15.1227L3.57332 15.1421Z"
        fill={hex}
      />
      <path
        d="M10.0186 15.1519C9.58827 15.1519 9.14817 15.1907 8.72764 15.1422C8.47336 14.9582 8.47336 15.055 8.46358 14.7741C8.4049 11.8385 8.46358 8.90295 8.46358 5.96739C8.4538 5.84144 8.49292 5.7058 8.58094 5.61861C8.76676 5.4539 9.72519 5.51203 10.0186 5.52172C10.2729 5.53141 10.3609 5.64767 10.3609 5.82206C10.4391 8.79638 10.3609 11.7901 10.3707 14.7741C10.3707 14.9388 10.2729 15.0357 10.1653 15.1519H10.0186Z"
        fill={hex}
      />
      <path
        d="M22.0681 25.6279C17.3835 25.5116 12.8652 24.0584 8.88473 21.6751C7.49599 20.8516 6.19526 19.873 5.02167 18.7589C4.96299 18.6911 4.92387 18.6135 4.89453 18.536C4.92387 18.381 5.12925 18.1969 5.29551 18.2357C5.34441 18.2454 5.39331 18.2551 5.44221 18.2841C9.10967 20.4543 13.2857 21.8495 17.4813 22.5567C21.0803 23.1574 24.7575 23.2155 28.3761 22.7117C31.4861 22.2854 34.4983 21.3941 37.4127 20.2315C37.6768 20.1443 37.9604 20.2412 38.1169 20.464C38.2636 20.6965 38.1658 20.9387 37.98 21.1034C37.7942 21.2682 37.1389 21.7235 36.9726 21.8204C35.7697 22.5276 34.557 23.2155 33.2465 23.7096C30.6255 24.7075 27.8773 25.376 25.0705 25.5795C24.0827 25.6473 23.0754 25.6473 22.0681 25.6279Z"
        fill={hex}
      />
      <path
        d="M8.87352 3.69578C8.37474 3.45357 8.17914 2.94978 8.2476 2.40723C8.28672 2.1069 8.50188 1.86469 8.72682 1.6903C9.01044 1.47715 9.54833 1.47715 9.87107 1.57404C10.5459 1.77749 10.7513 2.52349 10.5361 3.11448C10.2916 3.79267 9.46031 3.94768 8.87352 3.69578Z"
        fill={hex}
      />
      <path
        d="M41.4213 15.1413C41.2061 15.1316 41.0594 14.9476 40.9909 14.7732C39.8174 11.8376 38.6242 8.90204 37.4506 5.96647C37.4115 5.84052 37.4115 5.71458 37.4408 5.58863C37.5191 5.54987 37.6071 5.5305 37.6853 5.51112C38.1156 5.49174 38.546 5.51112 38.9763 5.52081C39.2208 5.5305 39.3186 5.63707 39.387 5.82115C40.365 8.3498 41.2257 10.9366 42.1059 13.504C42.9665 10.9269 43.9054 8.37887 44.8149 5.82115C44.8931 5.63707 45.0789 5.51112 45.2843 5.52081C45.5679 5.52081 46.1841 5.47237 46.4286 5.51112L46.6829 5.54987C46.6437 6.00522 46.7122 5.90834 46.5264 6.38307C45.4212 9.12487 44.277 11.857 43.2305 14.6181C43.1425 14.841 43.0936 15.0347 42.8491 15.1123C42.409 15.2285 41.8809 15.1704 41.4213 15.1413Z"
        fill={hex}
      />
      <path
        d="M33.2452 15.1662C32.6975 15.0209 32.2378 14.6818 31.9444 14.207C31.6999 13.7711 31.5728 13.2867 31.5728 12.7829C31.5532 10.9421 31.563 9.09161 31.5728 7.24114C31.5728 7.17332 31.563 7.09581 31.563 7.02799C31.074 6.99893 30.585 6.99893 30.096 6.98924C29.9004 6.98924 29.8418 6.80516 29.8222 6.67922C29.8124 6.4467 29.8222 6.20449 29.8418 5.97197C29.8907 5.8654 29.9885 5.78789 30.096 5.75882C30.6046 5.67163 31.1229 5.6135 31.6315 5.54568C31.7195 4.74155 31.7977 3.93742 31.8858 3.13329C31.9444 2.60043 33.1474 2.84264 33.4799 2.88139V5.5263C34.3112 5.51661 35.1425 5.50693 35.9738 5.51662C36.1107 5.51662 36.2574 5.63287 36.3552 5.72007C36.3552 6.03978 36.365 6.3595 36.3552 6.67922C36.3454 6.86329 36.1498 7.00862 35.9738 6.99893C35.1425 6.98924 34.3112 6.99893 33.4897 7.02799C33.4701 9.02379 33.4016 11.0777 33.5483 13.0638C33.5777 13.432 34.0667 13.6936 34.3992 13.7323C35.0153 13.8001 35.6217 13.7129 36.228 13.587C36.2867 13.6451 36.3454 13.7033 36.4139 13.7711C36.4236 13.8195 36.4334 13.868 36.4334 13.9261C36.4432 14.207 36.4334 14.4977 36.4041 14.7787C36.0716 15.4278 33.8124 15.3115 33.2452 15.1662Z"
        fill={hex}
      />
      <path
        d="M22.6335 15.3737C20.9611 15.2381 19.5431 14.0949 19.0736 12.4963C18.4673 10.4714 18.6726 7.94277 20.1787 6.37326C21.5773 4.9297 24.5504 4.80375 26.0467 6.12137C27.1909 7.12895 27.3083 8.96973 27.0442 10.3649C27.0051 10.5683 26.9171 10.6167 26.7606 10.733L20.5895 10.7233C20.6775 11.6922 20.8536 12.6416 21.6262 13.3101C22.8291 14.3468 25.5284 13.8527 26.8975 13.3489C26.9269 13.7655 26.9562 14.2111 26.878 14.618C26.301 15.364 23.5626 15.4706 22.6335 15.3737ZM25.2154 9.4929H25.4012C25.4892 8.80503 25.4403 7.95246 24.9905 7.38085C24.2374 6.4217 22.3597 6.39264 21.5088 7.2549C20.8927 7.87495 20.7166 8.65002 20.6188 9.48321C22.1543 9.4929 23.6897 9.48321 25.2154 9.4929Z"
        fill={hex}
      />
      <path
        d="M14.4691 15.1489C14.0388 15.1683 13.6085 15.1683 13.1782 15.1489C13.0315 15.1392 12.875 14.9358 12.875 14.7711C12.9239 11.7871 12.875 8.80305 12.8848 5.81904C12.8848 5.6834 13.0119 5.58652 13.1097 5.52839C13.5205 5.49933 13.9312 5.50901 14.3322 5.55746C14.6452 5.70278 14.6354 6.63286 14.6549 6.94289C15.5449 5.97405 16.6989 5.31525 18.0584 5.4412C18.1953 5.46057 18.3224 5.50901 18.43 5.59621C18.4593 6.05156 18.4593 6.49722 18.4398 6.95257C18.4007 7.03008 18.2931 7.15603 18.2051 7.15603C17.4227 7.14634 16.6696 7.10759 15.9166 7.36917C15.496 7.52419 15.1146 7.74702 14.7821 8.03767C14.7821 10.2757 14.7821 12.5234 14.7919 14.7614C14.7919 14.8486 14.7821 14.9261 14.7723 15.0133C14.6549 15.0617 14.5767 15.1392 14.4691 15.1489Z"
        fill={hex}
      />
      <path
        d="M38.4374 23.9571C39.0144 22.6492 39.5132 21.3025 39.6892 19.888C39.7185 19.6167 39.699 19.5199 39.5621 19.3164C39.0926 18.6285 35.6697 19.0451 34.8286 19.142C34.7308 19.1517 34.5743 19.0257 34.5156 18.9579C34.7014 18.7157 34.8188 18.5413 35.1122 18.396C36.0022 17.9407 36.9802 17.65 37.9777 17.5434C38.9264 17.4466 40.5303 17.4466 41.2638 18.0569C41.743 18.4444 40.9997 20.9343 40.7943 21.4478C40.3738 22.4845 39.7577 23.4824 38.8286 24.1412C38.7503 24.1799 38.6623 24.1993 38.5841 24.1896L38.4374 23.9571Z"
        fill={hex}
      />
    </svg>
  )
}
