import client from './campaignDetailsClient'

interface LivestreamRequest {
  video: string
}

export interface Comment {
  id?: string
  stream_id: string
  date: string
  text: string
  platform: 'youtube'
  author_name: string
}

export const getLivestreamComments = ({ video }: LivestreamRequest) =>
  client
    .get(`livestreams/${video}.json`)
    .then((res: { data: { Items: Comment[] } }) => res.data.Items)
