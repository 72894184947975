import React from 'react'
import { OneColorLogoProps, scaleDimensions } from './utils'

export const LgTvLogo: React.FC<OneColorLogoProps> = ({
  height = 32,
  color = '#141414',
  className,
}) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(
    height,
    47,
    18
  )
  const hex = color

  return (
    <svg
      className={className}
      height={scaledHeight}
      width={scaledWidth}
      aria-label="LGTV"
      viewBox="170 20 280 240"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m523.76 156.45h31.071v28.903c-5.7021 2.1745-16.894 4.3498-27.504 4.3498-34.368 0-45.797-17.446-45.797-52.874 0-33.811 10.881-53.708 45.216-53.708 19.135 0 29.991 6.004 38.99 17.453l23.741-21.811c-14.497-20.672-39.8-26.937-63.557-26.937-53.413-0.02127-81.498 29.113-81.498 84.721 0 55.332 25.377 85.327 81.212 85.327 25.639 0 50.697-6.5259 64.343-16.09v-80.138h-66.216zm-162.98 30.804h79.071v32.166h-117.23v-165.17h38.162z"
        fill={hex}
      />
      <path
        d="m98.971 112.6c2.9124-0.0139 5.7553-0.8909 8.1703-2.5197 2.4142-1.628 4.2925-3.935 5.3969-6.6298 1.1052-2.6948 1.3867-5.6571 0.80991-8.5114-0.57757-2.8551-1.9871-5.4747-4.0512-7.5289-2.0649-2.0542-4.6918-3.4515-7.5493-4.0144-2.8568-0.56285-5.8174-0.2667-8.5065 0.85081-2.6899 1.1183-4.9879 3.0073-6.6044 5.4305-1.6165 2.4224-2.4788 5.2693-2.4788 8.1817 0.0041 1.9405 0.39105 3.8614 1.138 5.6522 0.74773 1.7916 1.8399 3.418 3.2151 4.7866 1.376 1.3687 3.0081 2.4534 4.8022 3.1914 1.7949 0.73873 3.7174 1.1159 5.6579 1.111zm135.1 28.96h-64.955v-9.6216h74.475c0.10145 1.8636 0.15217 3.7264 0.15217 5.6121 0 57.936-47.218 104.9-105.45 104.9-58.234 0-105.46-46.935-105.46-104.9 0-57.96 47.211-104.9 105.45-104.9 1.5372 0 3.067 0.03109 4.5895 0.09408v9.5496c-1.5225-0.0679-3.0474-0.10144-4.5756-0.10144-52.931 0-95.856 42.694-95.856 95.348 0 52.655 42.919 95.357 95.856 95.357 51.482 0 93.54-40.395 95.766-91.07zm-65.103 40.085h-26.104v-98.073h-9.651v107.76h9.6731v-0.0646h26.104z"
        fill={hex}
      />
    </svg>
  )
}
