import { Campaign } from '@/services/api/campaign'

const getCampaignPixels = (campaign: Campaign) => {
  const pixels = []
  if (campaign.facebookTrackingPixel)
    pixels.push(campaign.facebookTrackingPixel)
  if (!campaign.disableDefaultFacebookPixel)
    pixels.push(process.env.NEXT_PUBLIC_FB_PIXEL as string)
  return pixels
}

export default getCampaignPixels
