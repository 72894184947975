import React, { useMemo } from 'react'
import { Text, AnimateIn } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import useCampaign from '@/services/hooks/useCampaign'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import { formatMoney } from '@/utils'
import PriorRaisesBreakdownItem from './PriorRaisesBreakdownItem'

const COLORS = ['#36312E', '#8E7758', '#C0A175', '#78BC92']

const getDisclaimerSymbol = (idx: number, char = '*') =>
  Array(idx + 1)
    .fill(char)
    .join('')

export interface Props {
  campaign: Campaign
  disclaimer: string
  className?: string
}

const PriorRaisesBreakdown: React.FC<Props> = ({
  campaign,
  disclaimer,
  className,
}) => {
  const { campaign: resolvedCampaign = campaign } = useCampaign(campaign.slug)
  const { priorRaises, capitalRaised, regulationTypeLabel, status } =
    resolvedCampaign
  const siteAssets = useSiteAssets()

  const { items, itemDisclaimers } = useMemo(() => {
    const disclaimers: string[] = []
    const items = []

    priorRaises.forEach(({ roundType, amountRaised, disclaimer }) => {
      if (disclaimer) {
        disclaimers.push(disclaimer)
      }

      const title = disclaimer
        ? `${roundType}${getDisclaimerSymbol(disclaimers.length)}`
        : roundType

      items.push({
        title,
        amount: amountRaised,
      })
    })

    items.push({
      title:
        status === 'currently_funding'
          ? `Reg ${regulationTypeLabel} Currently Funding`
          : `Reg ${regulationTypeLabel}`,
      amount: capitalRaised,
    })

    return { items, itemDisclaimers: disclaimers }
  }, [priorRaises, status, regulationTypeLabel, capitalRaised])

  if (priorRaises.length === 0) return null

  return (
    <div className={className}>
      <div className="flex flex-col space-y-4">
        <Text as="h4" preset="heading.eyebrow">
          {siteAssets.priorRaisesTitle}
        </Text>

        <AnimateIn
          variant={{
            easing: 'ease-in-out',
            from: 'opacity-0 w-0',
            to: 'opacity-100 w-full',
          }}
          speed="slow"
          className="h-10 rounded border border-gray-8 bg-core-gray-100"
        >
          <div className="flex h-full w-full">
            {items?.map(({ amount }, idx) => (
              <div
                key={amount}
                className="h-full"
                style={{
                  width: `${
                    (amount / siteAssets.priorRaisesGoalAmount) * 100
                  }%`,
                  backgroundColor: COLORS[idx],
                }}
              />
            ))}
          </div>
        </AnimateIn>

        <Text as="div" preset="heading.lg sm:heading.xl">
          {formatMoney(siteAssets.priorRaisesGoalAmount, {
            zeroFractionDigits: true,
          })}{' '}
          Total*
        </Text>

        <Text as="p" preset="body.sm" className="text-gray-5">
          {disclaimer}
        </Text>

        <ul className="overflow-hidden rounded-lg border border-gray-8">
          {items.map(({ title, amount }, idx) => (
            <AnimateIn key={title} as="li" variant="slideUp">
              <PriorRaisesBreakdownItem
                title={title}
                amount={amount}
                color={COLORS[idx]}
                isLast={idx === items.length - 1}
              />
            </AnimateIn>
          ))}
        </ul>

        {itemDisclaimers.map((itemDisclaimer, idx) => (
          <Text key={idx} as="p" preset="body.sm" className="text-gray">
            {getDisclaimerSymbol(idx + 1)}
            {itemDisclaimer}
          </Text>
        ))}
      </div>
    </div>
  )
}

export default PriorRaisesBreakdown
