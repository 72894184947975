import React from 'react'
import Image from 'next/image'
import { BaseLogoProps, scaleDimensions } from './utils'

export const AppleStoreDownloadLogo: React.FC<BaseLogoProps> = ({
  height = 24,
  className,
}) => {
  const { width: scaledWidth, height: scaledHeight } = scaleDimensions(
    height,
    83,
    24
  )

  return (
    <Image
      width={scaledWidth}
      height={scaledHeight}
      src={`https://images.angelstudios.com/image/upload/v1670459352/angel-studios/landing/apple-app-store-badge.png`}
      className={className}
      alt="Apple App Store Logo"
      aria-label="Download on the App Store"
    />
  )
}
