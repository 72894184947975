import { AnimateIn, PerkCard } from '@/components/common'
import { Perk } from '@/services/api/campaign'
import { SnakeToCamel } from '@/services/api/types'

interface Props {
  perks: SnakeToCamel<Perk>[]
}

export default function Perks({ perks }: Props) {
  if (!perks?.length) {
    return null
  }

  return (
    <>
      {perks.map((perk) => (
        <AnimateIn key={perk.id} variant="slideUp" className="mb-4 last:mb-0">
          <PerkCard perk={perk} />
        </AnimateIn>
      ))}
    </>
  )
}
