import { useState } from 'react'
import { Document } from '@contentful/rich-text-types'
import Markdown from '@/components/Markdown'
import { Accordion, TextBlock, Type } from '@/components/common'

interface qAndA {
  answer: string | Document
  question: string
}

interface Props {
  faq: {
    questions: qAndA[]
  }
}

function FAQ({ faq }: Props) {
  const [expanded, setExpanded] = useState(-1)

  if (faq.questions.length === 0) {
    return null
  }

  return (
    <Accordion expanded={expanded} setExpanded={setExpanded} speed="fast">
      {faq.questions.map((item, idx) => (
        <div
          key={item.question}
          className="group transition-all duration-300 ease-in-out"
        >
          <div className="border-b border-core-gray-300 group-last:border-b-0">
            <Type
              index={idx}
              as={Accordion.Summary}
              variant="paragraph-md"
              className="w-full py-4 font-bold"
            >
              {item.question}
            </Type>

            <Accordion.Details index={idx} className="pb-4">
              {typeof item.answer === 'object' ? (
                <TextBlock document={item.answer} />
              ) : (
                <Markdown source={item.answer} />
              )}
            </Accordion.Details>
          </div>
        </div>
      ))}
    </Accordion>
  )
}

export default FAQ
